import * as React from 'react';

export const UpArrow = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="8.346" height="8.44" viewBox="0 0 8.346 8.44">
      <g id="Group_1854" data-name="Group 1854" transform="translate(1700.061 -1827.939)">
        <path id="Path_4269" data-name="Path 4269" d="M-1690.269,1837.6v-3.9" transform="translate(-5.626 -2.221)" fill="none" stroke="#58b1f5" stroke-linecap="round" stroke-width="2"/>
        <path id="Path_4270" data-name="Path 4270" d="M0,0H4.4V4.4Z" transform="translate(-1699 1832.112) rotate(-45)" fill="#58b1f5" stroke="#58b1f5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      </g>
    </svg>
} 

export default UpArrow;