import * as shared from "../Utils/Shared";
import request from "../Utils/Axios";

export const loginWithAd = () => {
	const fetchUrl = `${shared.AppUrls.Server.loginWithAd}/`;
	return request({
		url: fetchUrl,
		method: "GET"
	});
};
export const loginWithSF = () => {
	const fetchUrl = `${shared.AppUrls.Server.loginWithSf}/`;
	return request({
		url: fetchUrl,
		method: "GET"
	});
};

export const getJWTToken = () => {
	//const token = localStorage.getItem("Identity.Token");
	const fetchUrl = `${shared.AppUrls.Server.webBaseUrl}${shared.AppUrls.Server.getJWTToken}`;
	return request({
		url: fetchUrl,
		method: "GET",
		withCredentials: true, //Need this to pass the auth cookies for the Active directory
		headers: {
			//'Authorization': `Bearer ${token}`
		}
	});
};

const SessionService = {
	loginWithAd,
	loginWithSF,
	getJWTToken
};

export default SessionService;
