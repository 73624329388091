import * as MobX from "mobx";
import * as MobXReact from "mobx-react";
import StoreContext from "./StoreContext";
import { setSessionLock } from "../Apis/AdminService";

interface UserSubmission {
	username: string;
	iSamsId: string;
	sessions: Session;
}

interface Session {
	sessionId: string;
	created: string;
	modified: string;
	userId: string;

	validated: boolean;
	submitted: boolean;
	independent: boolean;

	buildings: boolean;
	otherSchools: boolean;
	staffData: boolean;
	schoolStructure: boolean;
	academicStructure: boolean;
	pupilData: boolean;
	doctors: boolean;
	users: boolean;
	timetableStructure: boolean;
	medicalCentre: boolean;
	admissions: boolean;
	agencies: boolean;
	documents: boolean;
	feeBilling: boolean;
	discipline: boolean;
	externalExams: boolean;
	internalExams: boolean;
	pastoralManager: boolean;
	registrations: boolean;
	reportsAndAssessments: boolean;
	rewardsAndConducts: boolean;
	activities: boolean;
	sen: boolean;
}

export default class AdminStore {
	public stores: StoreContext;
	@MobX.observable UserSubmissions: UserSubmission[] = [];
	@MobX.observable NoSubmissions: boolean = false;

	constructor(stores: StoreContext) {
		this.stores = stores;
	}

	@MobX.action
	SetUserSubmissions(submissions: UserSubmission[]) {
		this.NoSubmissions = false;
		this.UserSubmissions = submissions;
		if (this.UserSubmissions.length === 0) this.NoSubmissions = true;
	}

	@MobX.action
	SetSessionLock(lock: boolean, index: number) {
		this.UserSubmissions[index].sessions.validated = lock;
	}
}
