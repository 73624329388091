import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface Props {
    loading: boolean;
    className?: string;
    onClick?: any;
    type?: "submit" | "button";
    color?: string;
}

@observer
export class ButtonLoader extends React.Component<Props> {
    static defaultProps: Props = {
        loading: false,
        color: "success"
    }

    OnClick = () => {
        this.props.onClick();
    }

    render() {
        return <Button style={{border: `none`, borderRadius: `4px`}} color={this.props.color} disabled={this.props.loading} type={this.props.type} className={this.props.className} onClick={this.OnClick}>
            {this.props.loading ?
                <React.Fragment>
                    <FontAwesomeIcon icon={faSpinner} spin /> {this.props.children}
                </React.Fragment>
                :
                this.props.children
            }
        </Button>
    }
}
