import * as React from "react";
import * as Reactstrap from "reactstrap";
import * as MobXReact from "mobx-react";
import { AppUrls } from "../../Utils/Shared";
import StoreContext from "../../Stores/StoreContext";
import styles from "./AdminLogin.module.scss";
import UserStore from "../../Stores/UserStore";
import BackgroundImg from "../../Content/Images/Background.png";
import ISamsLogoImg from "../../Content/Images/iSamsLogo.png";

interface Props {
	stores?: StoreContext;
}

interface State {
	Uploading: boolean;
	Spinner: boolean;
}

const defaultState: State = {
	Uploading: false,
	Spinner: false
};

@MobXReact.inject((context: { stores: StoreContext }) => ({ stores: context.stores }))
@MobXReact.observer
export class AdminLogin extends React.Component<Props, State> {
	private userStore: UserStore = this.props.stores!.UserStore as UserStore;

	state: State = defaultState;

	componentDidMount() {}

	private login = () => {
		window.location.href = AppUrls.Server.loginWithAd;
	};

	render() {
		return (
			<div
				className={`h-100 w-100 container-fluid ${styles.wrapper}`}
				style={{
					backgroundImage: `url(${BackgroundImg})`,
					backgroundColor: `#252834`,
					backgroundSize: `cover`
				}}>
				<Reactstrap.Row className={`{styles.row} align-items-center justify-content-center h-100`}>
					<Reactstrap.Col lg={4} className="text-center py-5 px-5" style={{ backgroundColor: "#fff" }}>
						<div className="">
							<div className="text-left">
								<img src={ISamsLogoImg} alt="iSams logo" />
								<p
									style={{
										marginTop: `4px`,
										color: `#9e9e9e`,
										fontWeight: 600,
										fontSize: `15px`
									}}>
									Administation - Any unauthorised access will be reported
								</p>
							</div>
							<Reactstrap.Button
								color="primary"
								style={{ border: `none`, borderRadius: `4px`, width: `100%` }}
								className="p-4 my-3"
								onClick={this.login}>
								Login using your Microsoft Account
							</Reactstrap.Button>
						</div>
					</Reactstrap.Col>
				</Reactstrap.Row>
			</div>
		);
	}
}
