import * as React from "react";
import * as Reactstrap from "reactstrap";
import { NavLink } from "react-router-dom";
import dashboard from "./Group 1904.svg";
import upload from "./Group 1906.svg";
import StoreContext from "../Stores/StoreContext";
import './Sidebar.scss';

interface Props {
	className?: string;
}

interface State {
	dasboardSelected: boolean;
	dashToolTip: boolean;
	uploadToolTip: boolean;
}


// const dash = {
// 	marginLeft: "33%"
// };
// const up = {
// 	marginLeft: "35%"
// };

export default class Sidebar extends React.Component<Props, State> {
	state: State = {
		dasboardSelected: false,
		dashToolTip: false,
		uploadToolTip: false
	};


	render() {
		
		const checkActive = (match: any, location: any) => {
		//some additional logic to verify you are in the home URI
			console.log(location.pathname);
			if (location.pathname == "/admin/")
				return false;
			else
				return true;
		}
		
		return <div className="side-bar">
			{(StoreContext.Instance.UserStore.IsAuthenticated &&
				StoreContext.Instance.UserStore.hasPermission("admin")) &&
				<React.Fragment>
					<div>
						<NavLink to="/admin/" activeClassName="is-active" onClick={() => this.setState({ dasboardSelected: true })}>
							<div className="icon-container text-center" id="dash-nav">
								<div className="black-strip"></div>
								<div className="blue-strip"></div>
								<img className="my-4" src={dashboard} />
							</div>
						</NavLink>
					</div>
				
					<Reactstrap.Tooltip className="my-tooltip" placement="right" isOpen={this.state.dashToolTip} target="dash-nav" toggle={this.toggleDashboard}>
						Admin dashboard
					</Reactstrap.Tooltip>
				</React.Fragment>
			}

			<div>
				<NavLink isActive={checkActive} activeClassName="is-active" to="/" onClick={() => this.setState({ dasboardSelected: false })}>
					<div className="icon-container text-center" id="upload-nav">
						<div className="black-strip"></div>
						<div className="blue-strip"></div>
						<img className="my-4" src={upload} />
					</div>
				</NavLink>
			</div>

			<Reactstrap.Tooltip className="my-tooltip" placement="right" isOpen={this.state.uploadToolTip} target="upload-nav"  toggle={this.toggleUpload}>
				Upload documents
			</Reactstrap.Tooltip>
			
		</div>
	}

	toggleUpload = () => {
		this.setState({
			uploadToolTip: !this.state.uploadToolTip
		});
	}

	toggleDashboard = () => {
		this.setState({
			dashToolTip: !this.state.dashToolTip
		});
	}
}
