import * as React from "react";
import * as MobX from "mobx";
import * as MobXReact from "mobx-react";
import * as ReactStrap from "reactstrap";
import StoreContext from "../Stores/StoreContext";
import styles from "./UserHeader.module.scss";
import UserStore from "../Stores/UserStore";
import { AppUrls } from "../Utils/Shared";
import Avi from "../Components/SVGs/Avi";
import "./UserHeader.scss";

interface Props {
	stores?: StoreContext;
}
interface State {
	dropdownOpen: boolean;
}

@MobXReact.inject((context: { stores: StoreContext }) => ({ stores: context.stores }))
@MobXReact.observer
export default class UserHeader extends React.Component<Props, State> {
	public userStore: UserStore = this.props.stores!.UserStore as UserStore;
	state: State = {
		dropdownOpen: false
	};

	toggle = () => {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		});
	};

	signOut = () => {
		this.userStore.SignOut();
		window.location.href = AppUrls.Client.signOut;
	};

	public render() {
		return (
			<div className={`row align-items-center user-header ${styles.userHeader}`}>
				<div className="col-12">
					<span className={`avi-svg ${styles.avatar}`}>
						<Avi />
					</span>
					{/*<img className={styles.avatar} src={avatar} alt="Unknown" />*/}
					<ReactStrap.ButtonDropdown className="sign-out-drp-down" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
						<ReactStrap.DropdownToggle caret size="lg" style={{color: `#373948`}}>
							{this.userStore.UserName}
						</ReactStrap.DropdownToggle>
						<ReactStrap.DropdownMenu right>
							<ReactStrap.DropdownItem className="cursor-pointer" onClick={this.signOut}>
								Sign Out
							</ReactStrap.DropdownItem>
						</ReactStrap.DropdownMenu>
					</ReactStrap.ButtonDropdown>
					{/* <span className={styles.username}>{this.userStore.UserName}</span> */}
				</div>
			</div>
		);
	}
}
