import * as React from "react";
import * as Reactstrap from "reactstrap";
import styles from "./YesNoModal.module.scss";

interface Props {
    IsOpen: boolean;
    Title: string;
    Body: JSX.Element | string;
    OnYes?: any;
    OnNo?: any;
}

interface State {

}

export default class YesNoModal extends React.Component<Props, State> {
    static defaultProps: Props = {
        IsOpen: false,
        Title: "Modal Title",
        Body: "Modal Body"
    }

    public render() {
        return (
            <Reactstrap.Modal isOpen={this.props.IsOpen} >
                <Reactstrap.ModalHeader>{this.props.Title}</Reactstrap.ModalHeader>
                <Reactstrap.ModalBody>
                    {this.props.Body}
                </Reactstrap.ModalBody>
                <Reactstrap.ModalFooter>
                    <Reactstrap.Button className="f-16" color="danger" onClick={this.props.OnYes}><
                        span className="pl-5 pr-5">Yes</span>
                    </Reactstrap.Button>{' '}
                    <Reactstrap.Button className={"f-16 " + styles.noButton} onClick={this.props.OnNo}>
                        <span className="pl-5 pr-5">No</span>
                    </Reactstrap.Button>
                </Reactstrap.ModalFooter>
            </Reactstrap.Modal>
        );
    }
}
