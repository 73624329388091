import React from "react";
import { RouteProps, RouteComponentProps } from "react-router-dom";
import { DefaultLayout } from "./DefaultLayout";
import { LoggedOutLayout } from "./LoggedOutLayout";
import { AdminLayout } from "./AdminLayout";
import StoreContext from "../Stores/StoreContext";

interface PrivateRouteProps extends RouteProps {
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
	redirect?: string;
}

export class Layout extends React.Component {
	public render() {
		return <React.Fragment>{this.getLayout()}</React.Fragment>;
	}

    getLayout() {
        if (StoreContext.Instance.UserStore.IsAuthenticated == false)
            return <LoggedOutLayout>{this.props.children}</LoggedOutLayout>;
        else {
            if (StoreContext.Instance.UserStore.hasPermission("admin"))
                return <AdminLayout>{this.props.children}</AdminLayout>;
            else 
                return <DefaultLayout>{this.props.children}</DefaultLayout>;
        }
	}
}
