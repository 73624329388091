export class TemplateErrorContainer {
    [key: string]: TemplateErrorSheet;
}

export class TemplateErrorSheet {
    Name: string = "";
    Sheets: TemplateError[] = [];
}

export class TemplateError {
    public RowNumber: number;
    public ColumnName: string;
    public Description: string;
    public ErrorType: TemplateErrorType;
}

export enum TemplateErrorType {
    FailedToReadFile = 0,
    IncorrectDataType = 1,
    UniqueValue = 2,
    LinkedValue = 3,
    ColumnMissing = 4,
    ColumnDuplicate = 5,
    ColumnUnexpected = 6,
    SheetNotFound = 7,
    Other = 10
}