import * as React from "react";
import * as MobX from "mobx";
import * as MobXReact from "mobx-react";
import * as Reactstrap from "reactstrap";
import StoreContext from "../Stores/StoreContext";
import UserStore from "../Stores/UserStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Template } from "../Models/Template";
import { TemplateErrorType, TemplateErrorSheet } from "../Models/TemplateError";

interface Props {
	stores?: StoreContext;
	template: Template;
}
interface State {
	collapse: boolean;
}

const bottomBorder = {
	borderBottom: "2px solid rgba(230,54,44,0.25)"
};

@MobXReact.inject((context: { stores: StoreContext }) => ({ stores: context.stores }))
@MobXReact.observer
export default class ErrorView extends React.Component<Props, State> {
	public userStore: UserStore = this.props.stores!.UserStore as UserStore;
	state: State = {
		collapse: true
	};

	private GetRowNumberFormatted(rowNumber: number): string {
		let rowNumberFormatted = rowNumber.toString();

		switch (rowNumber) {
			case -2:
				rowNumberFormatted = "Over 10 rows";
				break;
			case -1:
				rowNumberFormatted = "Every row";
				break;
			default:
				rowNumberFormatted = rowNumber.toString();
				break;
		}

		return rowNumberFormatted;
	}

	public render() {
		return <div>
			{this.props.template.TemplateErrorSheet.length > 0 ?
				<div>{this.getErrors()}</div>
				:
				<div>No errors</div>
			}
		</div>
	}

	private getErrors() {
		let retval: any = [];
		for (let i = 0; i < this.props.template.TemplateErrorSheet.length; i++) {
			let sheet = this.props.template.TemplateErrorSheet[i];
			retval.push(<div key={'sheet-' + i} className="mt-3" style={{
				padding: sheet.Sheets.length > 0 && `6px`,
				backgroundColor: sheet.Sheets.length > 0 && `rgba(230, 54, 44, 0.15)`,
				fontSize: `11px`
			}}>
				{sheet.Name}
				{sheet.Sheets.length <= 0 &&
					<span className="ml-3" style={{ color: `green` }}>
						<FontAwesomeIcon icon={faCheck} />
					</span>
				}
			</div>);
			if (sheet.Sheets.length > 0) {
				for (let x = 0; x < sheet.Sheets.length; x++) {
					retval.push(
						<div className="f-11 py-2" style={bottomBorder} key={'sheet-error-'+ x + "-" + i}>
							<Reactstrap.Row>
								<Reactstrap.Col lg="10">
									<div className="ml-4"><span style={{ fontWeight: 600 }}>Error: </span>{TemplateErrorType[sheet.Sheets[x].ErrorType]}</div>
								</Reactstrap.Col>
								<Reactstrap.Col lg="1">
									<div style={{ fontWeight: 600 }}>Row:</div>
								</Reactstrap.Col>
								<Reactstrap.Col lg="1">
									<div>{this.GetRowNumberFormatted(sheet.Sheets[x].RowNumber)}</div>
								</Reactstrap.Col>
							</Reactstrap.Row>
							<Reactstrap.Row>
								<Reactstrap.Col lg="10">
									<div className="ml-4"><span style={{ fontWeight: 600 }}>Description: </span>{sheet.Sheets[x].Description}</div>
								</Reactstrap.Col>
								<Reactstrap.Col lg="1">
									<div style={{ fontWeight: 600 }}>Column:</div>
								</Reactstrap.Col>
								<Reactstrap.Col lg="1">
									<div>{sheet.Sheets[x].ColumnName}</div>
								</Reactstrap.Col>
							</Reactstrap.Row>
						</div>
					);
				}
			}
		}
		return <div className="ml-4">
			<div style={{
				fontSize: `14px`,
				fontWeight: 600,
				color: `#3c3c3c`,
			}}>
				Sheet Name
			</div>

			{retval}
		</div>;
	}
}
