import * as React from "react";
import * as Reactstrap from "reactstrap";
import * as MobXReact from "mobx-react";
import * as MobX from "mobx";
import StoreContext from "../../Stores/StoreContext";
import moment from "moment";
import AdminService from "../../Apis/AdminService";
import AdminStore from "../../Stores/AdminStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faLock, faUnlock, faDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { AdminWorkbook } from "./AdminWorkbook";
import styles from "../../Components/TemplateView.module.scss";
import "./AdminRow.scss";
import { Misc } from "../../Utils";

interface Props {
	stores?: StoreContext;
	index: number;
}

interface State {
	lockIsLoading: boolean;
}
@MobXReact.inject((context: { stores: StoreContext }) => ({ stores: context.stores }))
@MobXReact.observer
export class AdminRow extends React.Component<Props, State> {
	private adminStore: AdminStore = this.props.stores!.AdminStore as AdminStore;

	state: State = {
		lockIsLoading: false,
	}

	@MobX.observable isOpen: boolean = false;

	@MobX.action
	toggleIsOpen() {
		this.isOpen = !this.isOpen;
	}

	private workbookCount() {
		let session = this.adminStore.UserSubmissions[this.props.index].sessions;

		let count = 0;
		Object.entries(session).forEach(([key, value]) => {
			if (typeof value === "boolean") {
				if (key != "validated" && key != "submitted" && key != "independent") {
					if (value === true) {
						count++;
					}
				}
			}
		});

		return Intl.NumberFormat("en-GB", { minimumIntegerDigits: 2 }).format(count);
	}

	private downloadZipFile = () => {
		let sessionId = this.adminStore.UserSubmissions[this.props.index].sessions.sessionId;
		AdminService.getDownloadStream(sessionId, sessionId + ".zip").then((workbook:any) => {
			let sampleArr = Misc.base64ToArrayBuffer(workbook);
			Misc.saveByteArray(`${this.adminStore.UserSubmissions[this.props.index].iSamsId}-${sessionId}.zip`, sampleArr);
		})
		.catch(() => {
			window.alert("Failed to download file - Could not be found.");
		});;
	};

	render() {
		return (
			<React.Fragment>
				<Reactstrap.Row
					onClick={() => this.toggleIsOpen()}
				/*	className={`admin-row ad-${
						this.adminStore.UserSubmissions[this.props.index].sessions.validated ? "green" : "blue"
					}`}*/
				>
					<Reactstrap.Col lg={6}>
						{this.adminStore.UserSubmissions[this.props.index].username} (
						{this.adminStore.UserSubmissions[this.props.index].iSamsId})
					</Reactstrap.Col>

					<Reactstrap.Col lg={2}>
						{this.workbookCount()} workbooks submitted&nbsp;&nbsp;
					</Reactstrap.Col>

					<Reactstrap.Col lg={2}>
						{moment(this.adminStore.UserSubmissions[this.props.index].sessions.created).format("DD/MM/YY")}
					</Reactstrap.Col>

					<Reactstrap.Col lg={2}>
						<div className="d-flex align-items-center">
							{this.adminStore.UserSubmissions[this.props.index].sessions.validated ? (
								<Reactstrap.Badge color="success">
									<FontAwesomeIcon icon={faLock} /> Locked
								</Reactstrap.Badge>
							) : (
								<Reactstrap.Badge color="info">
									<FontAwesomeIcon icon={faUnlock} /> Unlocked
								</Reactstrap.Badge>
							)}
							<div className="ml-auto mr-4">
								<FontAwesomeIcon
									className={`${styles.rotate} ${this.isOpen ? styles.rotateDown : ""}`}
									icon={faChevronUp}
								/>
							</div>
						</div>
					</Reactstrap.Col>
				</Reactstrap.Row>

				<React.Fragment>
					<Reactstrap.Collapse isOpen={this.isOpen} className="px-3 mb-2">
						<div style={{ border: `1px solid rgba(170, 232, 170, 0.45)`, borderTop: `none` }}>
							<Reactstrap.Row
								className="pt-3 pb-2"
								style={{ borderBottom: `1px solid rgba(0, 0, 0, .1)`, fontSize: `13px` }}>
								<Reactstrap.Col>
									<strong>Required workbooks</strong>
								</Reactstrap.Col>
							</Reactstrap.Row>

							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.otherSchools}
								workbookName={"Other Schools"}
								workbookNumber={1}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.staffData}
								workbookName={"Staff Data"}
								workbookNumber={2}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.schoolStructure}
								workbookName={"School Structure"}
								workbookNumber={3}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.academicStructure}
								workbookName={"Academic Structure"}
								workbookNumber={4}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.pupilData}
								workbookName={"Pupil Data"}
								workbookNumber={5}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.timetableStructure}
								workbookName={"Timetable Structure"}
								workbookNumber={6}
								index={this.props.index}
							/>

							<Reactstrap.Row>
								<Reactstrap.Col>
									<hr style={{ borderTop: `4px dotted rgba(177, 174, 174, 1)` }} />
								</Reactstrap.Col>
							</Reactstrap.Row>

							<Reactstrap.Row
								className="pb-2"
								style={{ borderBottom: `1px solid rgba(0, 0, 0, .1)`, fontSize: `13px` }}>
								<Reactstrap.Col>
									<strong>Additional & linked workbooks</strong>
									{this.adminStore.UserSubmissions.length <= 6 &&
										<p className="mt-2 ml-4">No additional or linked workbooks have been submitted.</p>
									}
								</Reactstrap.Col>
							</Reactstrap.Row>

							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.buildings}
								workbookName={"Buildings"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.doctors}
								workbookName={"Doctors"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.users}
								workbookName={"Users"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.medicalCentre}
								workbookName={"Medical Centre"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.admissions}
								workbookName={"Admissions"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.agencies}
								workbookName={"Agencies"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.documents}
								workbookName={"Documents"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.feeBilling}
								workbookName={"Fee Billing"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.discipline}
								workbookName={"Discipline"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.externalExams}
								workbookName={"External Exams"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.internalExams}
								workbookName={"Internal Exams"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.pastoralManager}
								workbookName={"Pastoral Manager"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.registrations}
								workbookName={"Registrations"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.reportsAndAssessments}
								workbookName={"Reports and Assessments"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.rewardsAndConducts}
								workbookName={"Rewards and Conducts"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.activities}
								workbookName={"Activities"}
								index={this.props.index}
							/>
							<AdminWorkbook
								exists={this.adminStore.UserSubmissions[this.props.index].sessions.sen}
								workbookName={"SEN"}
								index={this.props.index}
							/>

							<hr />

							<div className="text-right my-3 mr-5">
								<Reactstrap.Button color="primary" className="mr-4" onClick={this.downloadZipFile} >
									<FontAwesomeIcon icon={faDownload} className="mr-2" />Download all as .ZIP
								</Reactstrap.Button>

								{this.state.lockIsLoading ?
									<Reactstrap.Button disabled>
										<FontAwesomeIcon icon={faSpinner} spin />
									</Reactstrap.Button>
									:
									<Reactstrap.Button onClick={() => this.ToggleLock()}>
										{this.adminStore.UserSubmissions[this.props.index].sessions.validated ? (
											<>
												<FontAwesomeIcon icon={faUnlock} /> Unlock
										</>
										) : (
												<>
													<FontAwesomeIcon icon={faLock} /> Lock
										</>
											)}
									</Reactstrap.Button>
								}
							</div>
						</div>
					</Reactstrap.Collapse>
				</React.Fragment>
			</React.Fragment>
		);
	}

	ToggleLock = () => {
		this.setState({
			lockIsLoading: true,
		});
		AdminService.setSessionLock(
			!this.adminStore.UserSubmissions[this.props.index].sessions.validated,
			this.adminStore.UserSubmissions[this.props.index].sessions.sessionId
		).then(() => {
			this.adminStore.SetSessionLock(
				!this.adminStore.UserSubmissions[this.props.index].sessions.validated,
				this.props.index
			);

			this.setState({
				lockIsLoading: false,
			});
		})
		.catch(() => {
				this.setState({
					lockIsLoading: false,
				});
		});
	};
}
