import * as shared from "../Utils/Shared";
import request, { requestExcelFile } from "../Utils/Axios";

export const getUserSubmissions = () => {
	const fetchUrl: string  = `${shared.AppUrls.Server.adminController.getUserSubmissions}`;
	return request({
		url: fetchUrl,
		method: "GET"
	});
};

export const getDownloadStream = (sessionId: string, fileName: string) => {
	const fetchUrl: string = `${shared.AppUrls.Server.adminController.getWorkbookMemoryStream}/${sessionId}/${fileName}`;
	return requestExcelFile({
		url: fetchUrl,
		method: "GET"
	});
};

export const setSessionLock = (lock: boolean, sessionId: string)=> {
	const postUrl: string  = `${shared.AppUrls.Server.adminController.postSessionLock}`;
	return request({
		url: postUrl,
		method: "POST",
		data: { lock: lock, sessionId: sessionId }
	});
};

const AdminService = {
	getUserSubmissions,
	getDownloadStream,
	setSessionLock
};

export default AdminService;
