import * as MobX from "mobx";
import { TemplateContainer } from "../Models/TemplateContainer";
import { Template } from "../Models/Template";
import * as MobXReact from "mobx-react";
import StoreContext from "./StoreContext";

export default class TemplateStore {
	@MobX.observable public TemplateContainer: TemplateContainer = new TemplateContainer();
	@MobX.observable public SchoolIsIndependent: boolean = false;
	@MobX.observable public CanValidate: boolean = false;
	@MobX.observable public Validating: boolean = false;
	@MobX.observable public Validated: boolean = false;
	@MobX.observable public Submitted: boolean = false;
	@MobX.observable public Active: boolean = false;
	@MobX.observable public Errored: boolean = false;
	@MobX.observable public isLoading: boolean = false;

	constructor(stores: StoreContext) {
		this.InitTemplates();
	}

	private InitTemplates = () => {
		this.TemplateContainer = new TemplateContainer();
		this.TemplateContainer.Buildings = new Template(1, "Buildings", true, 1);
		this.TemplateContainer.OtherSchools = new Template(2, "Other Schools", true, 2);

		this.TemplateContainer.StaffData = new Template(3, "Staff Data", true, 3);
		this.TemplateContainer.StaffData.TemplateDependencies.push(this.TemplateContainer.OtherSchools);

		this.TemplateContainer.SchoolStructure = new Template(4, "School Structure", true, 4);
		this.TemplateContainer.SchoolStructure.TemplateDependencies.push(this.TemplateContainer.StaffData);

		this.TemplateContainer.AcademicStructure = new Template(5, "Academic Structure", true, 5);
		this.TemplateContainer.AcademicStructure.TemplateDependencies.push(this.TemplateContainer.StaffData);

		this.TemplateContainer.PupilData = new Template(6, "Pupil Data", true, 6);
		this.TemplateContainer.PupilData.TemplateDependencies.push(this.TemplateContainer.StaffData);
		this.TemplateContainer.PupilData.TemplateDependencies.push(this.TemplateContainer.SchoolStructure);
		this.TemplateContainer.PupilData.TemplateDependencies.push(this.TemplateContainer.OtherSchools);

		this.TemplateContainer.Doctors = new Template(7, "Doctors");
		this.TemplateContainer.Doctors.TemplateDependencies.push(this.TemplateContainer.PupilData);

		this.TemplateContainer.Users = new Template(8, "Users");
		this.TemplateContainer.Users.TemplateDependencies.push(this.TemplateContainer.StaffData);

		this.TemplateContainer.TimetableStructure = new Template(9, "Timetable Structure", true, 7);

		this.TemplateContainer.MedicalCentre = new Template(10, "Medical Centre");
		this.TemplateContainer.MedicalCentre.TemplateDependencies.push(this.TemplateContainer.PupilData);

		this.TemplateContainer.Admissions = new Template(11, "Admissions");
		this.TemplateContainer.Admissions.TemplateDependencies.push(this.TemplateContainer.PupilData);

		this.TemplateContainer.Agencies = new Template(12, "Agencies");
		this.TemplateContainer.Agencies.TemplateDependencies.push(this.TemplateContainer.PupilData);

		this.TemplateContainer.Documents = new Template(13, "Documents");
		this.TemplateContainer.Documents.TemplateDependencies.push(this.TemplateContainer.PupilData);

		this.TemplateContainer.FeeBilling = new Template(14, "Fee Billing");
		this.TemplateContainer.Discipline = new Template(15, "Discipline");
		this.TemplateContainer.Discipline.TemplateDependencies.push(this.TemplateContainer.Buildings);
		this.TemplateContainer.Discipline.TemplateDependencies.push(this.TemplateContainer.PupilData);
		this.TemplateContainer.Discipline.TemplateDependencies.push(this.TemplateContainer.StaffData);

		this.TemplateContainer.ExternalExams = new Template(16, "External Exams");
		this.TemplateContainer.ExternalExams.TemplateDependencies.push(this.TemplateContainer.PupilData);

		this.TemplateContainer.InternalExams = new Template(17, "Internal Exams");
		this.TemplateContainer.InternalExams.TemplateDependencies.push(this.TemplateContainer.PupilData);
		this.TemplateContainer.InternalExams.TemplateDependencies.push(this.TemplateContainer.SchoolStructure);
		this.TemplateContainer.InternalExams.TemplateDependencies.push(this.TemplateContainer.AcademicStructure);

		this.TemplateContainer.PastoralManager = new Template(18, "Pastoral Manager");
		this.TemplateContainer.PastoralManager.TemplateDependencies.push(this.TemplateContainer.PupilData);
		this.TemplateContainer.PastoralManager.TemplateDependencies.push(this.TemplateContainer.StaffData);

		this.TemplateContainer.Registrations = new Template(19, "Registrations");
		this.TemplateContainer.Registrations.TemplateDependencies.push(this.TemplateContainer.PupilData);

		this.TemplateContainer.ReportsAndAssessments = new Template(20, "Reports and Assessments");
		this.TemplateContainer.ReportsAndAssessments.TemplateDependencies.push(this.TemplateContainer.PupilData);
		this.TemplateContainer.ReportsAndAssessments.TemplateDependencies.push(this.TemplateContainer.StaffData);
		this.TemplateContainer.ReportsAndAssessments.TemplateDependencies.push(this.TemplateContainer.SchoolStructure);

		this.TemplateContainer.RewardsAndConducts = new Template(21, "Rewards and Conducts");
		this.TemplateContainer.RewardsAndConducts.TemplateDependencies.push(this.TemplateContainer.PupilData);
		this.TemplateContainer.RewardsAndConducts.TemplateDependencies.push(this.TemplateContainer.StaffData);
		this.TemplateContainer.RewardsAndConducts.TemplateDependencies.push(this.TemplateContainer.AcademicStructure);

		this.TemplateContainer.Activities = new Template(22, "Activities");
		this.TemplateContainer.Activities.TemplateDependencies.push(this.TemplateContainer.StaffData);

		this.TemplateContainer.SEN = new Template(23, "SEN");
		this.TemplateContainer.SEN.TemplateDependencies.push(this.TemplateContainer.StaffData);

		this.TemplateContainer.NewSchool = new Template(24, "New School");
		this.TemplateContainer.ExistingSchool = new Template(25, "Existing School");
	}

	@MobX.action 
	public SetLoading(bool: boolean = true) {
		this.isLoading = bool;
	}

	@MobX.computed
	get TotalFilesUploaded(): number {
		return this.RequiredFilesUploaded + this.OtherFilesUploaded;
	}

	@MobX.computed
	get TotalFilesNeeded(): number {
		var count: number = 0;
		Object.keys(this.TemplateContainer).forEach(value => {
			if (
				this.TemplateContainer[value].IsRequired ||
				this.TemplateContainer[value].IsVisible ||
				this.TemplateContainer[value].Uploaded
			) {
				count++;
			}
		});

		return count;
	}

	@MobX.computed
	get RequiredFilesUploaded(): number {
		let count: number = 0;
		Object.keys(this.TemplateContainer).forEach(value => {
			if (this.TemplateContainer[value].IsRequired && this.TemplateContainer[value].Uploaded) {
				count++;
			}
		});

		return count;
	}

	@MobX.computed
	get OtherFilesUploaded(): number {
		let count: number = 0;
		Object.keys(this.TemplateContainer).forEach(value => {
			if(this.TemplateContainer[value] !== undefined)
			{
				if (!this.TemplateContainer[value].IsRequired && this.TemplateContainer[value].Uploaded) {
					count++;
				}
			}
		});
		return count;
	}

	@MobX.action
	public SetCanValidate(state: boolean): void {
		this.CanValidate = true;
	}

	@MobX.action
	public SetTemplateToUploaded(templateName: string, state: boolean = true): void {
		// const keys = Object.keys(this.TemplateContainer);
		let name: string = templateName.replace(" ", "");
		let template: Template | null = this.TemplateContainer[name];
		if (template) {
			template.Uploaded = state;
			template.IsVisible = state;
			template.IsUploading = false;
			// template.IsRequired = state;
			this.checkDependencies(template);
			if (this.RequiredFilesUploaded >= this.requiredFilesNeeded()) {
				this.CanValidate = true;
			} else {
				this.CanValidate = false;
			}
		}
	}

	@MobX.action
	public SetTemplateIsUploading(templateName: string, state: boolean = true): void {
		// const keys = Object.keys(this.TemplateContainer);
		//  name = templateName.replace(" ", "");
		// var template = this.TemplateContainer[name];
		var template: Template | null = this.findTemplate(templateName);
		if (template) {
			template.Reset();
			template.IsUploading = true;
		}
	}

	@MobX.action
	public SetTemplateToValidated(templateName: string, state: boolean = true): void {
		//  keys = Object.keys(this.TemplateContainer);
		// var name = templateName.replace(" ", "");
		// var template = this.TemplateContainer[name];
		var template: Template | null = this.findTemplate(templateName);
		if (template) {
			template.IsUploading = false;
		}
	}

	@MobX.action
	public SetTemplateErrorCount(templateName: string, state: boolean = true): void {
		// const keys = Object.keys(this.TemplateContainer);
		// var name = templateName.replace(" ", "");
		// var template = this.TemplateContainer[name];
		var template: Template | null = this.findTemplate(templateName);
		if (template) {
			template.Uploaded = false;
			template.IsUploading = state;
		}
	}

	private findTemplate(templateName: string): Template | null {
		let name: string = templateName.replace(" ", "");
		let template: Template | null= this.TemplateContainer[name];
		if (template) {
			return template;
		}
		return null;
	}

	private requiredFilesNeeded(): number {
		let count: number = 0;
		Object.keys(this.TemplateContainer).forEach(value => {
			if (this.TemplateContainer[value].IsRequired) {
				count++;
			}
		});

		return count;
	}

	private checkDependencies(template: Template): void {
		if (template.Uploaded && template.TemplateDependencies.length > 0) {
			template.TemplateDependencies.forEach(dep => {
				dep.IsRequired = true;
				dep.IsVisible = true;
			});
		}
	}

	@MobX.action
	public SetSchoolDependantStatus(state: boolean): void {
		this.SchoolIsIndependent = state;
	}

	@MobX.action
	public Reset = (keepFiles: boolean = false) => {
		this.isLoading = false;
		this.Errored = false;
		this.Validated = false;
		this.CanValidate = false;
		this.Validating = false;
		this.Submitted = false;
		if(keepFiles === false)
			this.InitTemplates();
	}

	@MobX.action
	public SetValidating = (state: boolean) => {
		this.Validating = state;
	}

	@MobX.action
	public SetErrored = (state: boolean) => {
		this.Errored = state;
	}

	@MobX.action
	public SetValidated = (state: boolean) => {
		this.Validated = state;
	}

	@MobX.action
	public SetSubmitted = (state: boolean) => {
		this.Submitted = state;
	}

	@MobX.action
	public SetActive = (state: boolean) => {
		this.Active = state;
	}
}
