// Libs
import * as History from "history";
import TemplateStore from "./TemplateStore";
import UserStore from "./UserStore";
import AdminStore from "./AdminStore";

// App
export default class StoreContext {
	public static Instance: StoreContext;
	public readonly history: History.History;
	public readonly TemplateStore: TemplateStore;
	public readonly UserStore: UserStore;
	public readonly AdminStore: AdminStore;

	constructor(history: History.History) {
		StoreContext.Instance = this;
		this.history = history;
		this.TemplateStore = new TemplateStore(this);
		this.UserStore = new UserStore(this);
		this.AdminStore = new AdminStore(this);
	}
}
