//import { ValidateJwtToken } from "../Utils/Misc";
import * as MobX from "mobx";
import * as MobXReact from "mobx-react";
import StoreContext from "./StoreContext";
import * as Utils from "../Utils";
import AuthService from "../Apis/AuthService";

export default class UserStore {
	public stores: StoreContext;
	@MobX.observable public IsAuthenticated: boolean = false;
	@MobX.observable public UserName: string = "";
	@MobX.observable public SessionId: string = "";
	@MobX.observable public permissions: string[] = [];
	public jwtToken: string = "";

	constructor(stores: StoreContext) {
		this.stores = stores;
		//let token = localStorage.getItem("Identity.Token");
		// if (this.isJwtValid(token)) {
		// 	//check token
		// 	MobX.runInAction(() => {
		// 		this.stores.history.push("/");
		// 	});
		// } else
		{
			AuthService.getJWTToken().then((token:any) => {
				let isValid = this.isJwtValid(token);
				if (isValid) {
					MobX.runInAction(() => {
						localStorage.setItem("Identity.Token", token);
						this.stores.history.push("/");
					});
				}
			});
		}
	}

	private isJwtValid = (token: string) => {
		let decodedToken: any = Utils.Misc.ValidateJwtToken(token);
		if (decodedToken == null) {
			return false;
		}

		this.jwtToken = token;
		MobX.runInAction(() => {
			this.UserName = decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
			this.IsAuthenticated = true;
			let roles = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
			for (const role of roles) {
				this.permissions.push(role);
			}
		});
		return true;
	};

	public hasPermission = (target: string, level?: "read" | "write") => {
		for (const userPermission of this.permissions) {
			const userPermissionComponents = userPermission.split(":");
			const userPermissionTarget = userPermissionComponents[0];

			if (userPermissionTarget === "global") {
				return true;
			}

			if (userPermissionTarget === target) {
				if (userPermissionComponents.length === 1) {
					return true;
				}

				if (level == null) {
					return true;
				}

				const userPermissionLevel = userPermissionComponents[1];

				if (level === userPermissionLevel) {
					return true;
				}

				if (level === "write" && userPermissionLevel === "read") {
					return true;
				}
			}
		}

		return false;
	};

	@MobX.action
	public SetUserName(state: string) {
		this.UserName = state;
	}

	@MobX.action
	public SetSessionId(state: string) {
		this.SessionId = state;
	}

	@MobX.action
	public CreateNewSession() {
		this.SessionId = Utils.Misc.GetNewGuid();
		return this.SessionId;
	}

	@MobX.action
	public AbortSession() {
		this.SessionId = "";
	}

	@MobX.action
	public SignOut() {
		this.IsAuthenticated = false;
		this.jwtToken = "";
		localStorage.removeItem("Identity.Token");
	}
}
