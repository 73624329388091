import axios, { AxiosPromise } from "axios";

export const AppUrls = {
	Server: {
		apiBaseUrl: "http://localhost:49216/",
		webBaseUrl: `${window.location.protocol}//${window.location.host}`,
		//apiBaseUrl: "https://isams-api.azurewebsites.net/",
		loginWithAd: "/auth/loginwithad",
		//loginWithSf: "https://viking99.my.salesforce.com/idp/login?app=0sp4J000000KyjB&sdtd=1",
        loginWithSf: "https://identity.isams.com/idp/login?app=0sp1n000000GmrQ",
		getJWTToken: "/auth/getjwttoken",
		findSessionUrl: "api/Session/Get",
		findCurrentSessionUrl: "api/Session/GetCurrent",
		uploadFileUrl: "api/Upload/Upload",
		createSessionUrl: "api/Session/Create",
		abortSessionUrl: "api/Session/Abort",
		getOpenSessions: "api/Session/GetOpenSessions",
		validateAllFiles: "api/Validate/ValidateAllFiles",
		submitAllFiles: "api/Validate/SubmitAllFiles",
		adminController: {
			getUserSubmissions: "api/Admin/UserSubmissions",
			getWorkbookMemoryStream: "api/Admin/WorkbookMemoryStream",
			postSessionLock: "api/Admin/SessionLock"
		}
	},
	Client: {
		login: "/login",
		signOut: "/auth/signout",
		loginWithReturnPath: "/login/:returnPath?",
		adminLogin: "/admin/login",
		adminLoginWithReturnPath: "/admin/login/:returnPath?",
		Error: {
			AccessDenied: "/error/accessdenied",
			LoginRequired: "/error/loginrequired",
			Root: "/error",
			UnknownError: "/error/unknownerror"
		}
	}
};

export const getBaseUrl = (): any => {
	return axios.get("/api/session/getapiurl");
};
