import * as React from "react";
import * as ReactStrap from "reactstrap";
import UserHeader from "../Components/UserHeader";
import Sidebar from "../Components/Sidebar";
import "./DefaultLayout.scss";

// Only difference; no side bar for non-admins. 
export class DefaultLayout extends React.Component {
	public render() {
		return (
			<div className="defaultlayout w-100 h-100">
				<div className="outer-grey-container" />
				<ReactStrap.Container fluid className="d-flex flex-column px-0" style={{ minHeight: `100%` }}>
					<div className="header" />
					<UserHeader />
					<div className="layoutwrapper" style={{ flexGrow: 1 }}>
						<div className="d-flex w-100" id="content" >
							<div className={`w-100 px-4 py-4`} style={{ backgroundColor: `#ECECEE` }}>
								<div style={{ backgroundColor: `white` }} className="h-100 white-content-container">
									{this.props.children}
								</div>
							</div>
						</div>
					</div>
				</ReactStrap.Container>
			</div>
		);
	}
}
