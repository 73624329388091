import axios, { AxiosInstance } from "axios";
import * as shared from "../Utils/Shared";
import StoreContext from "../Stores/StoreContext";

interface AxiosOptions {
	url: string;
	method: "GET" | "POST";
	data?: {};
	headers?: {};
	withCredentials?: boolean;
}

let client: any = null;

export const requestExcelFile = function(options: AxiosOptions) {
	if (!client) {
		client = axios.create({
			baseURL: shared.AppUrls.Server.apiBaseUrl,
			//withCredentials: true,
			headers: {
				"Content-Type": "application/vnd.ms-excel"
				//"X-Correlation-Id": (window as any).correlationId
			}
		});
	}
	const onSuccess = (response: any) => {
		console.debug("Request Successful!", response);
		return response.data;
	};

	const onError = (error: any) => {
		console.error("Request Failed:", error.config);

		if (error.response) {
			// Request was made but server responded with something
			// other than 2xx
			console.error("Status:", error.response.status);
			console.error("Data:", error.response.data);
			console.error("Headers:", error.response.headers);
		} else {
			// Something else happened while setting up the request
			// triggered the error
			console.error("Error Message:", error.message);
		}

		return Promise.reject(error.response || error.message);
	};

	if (localStorage.getItem("Identity.Token")) {
		//Add a header if we have one
		options.headers = { Authorization: `Bearer ${localStorage.getItem("Identity.Token") || ""}` };
	}

	return client(options)
		.then(onSuccess)
		.catch(onError);
};

const request = function(options: AxiosOptions) {
	if (!client) {
		client = axios.create({
			baseURL: shared.AppUrls.Server.apiBaseUrl,
			//withCredentials: true,
			headers: {
				"Content-Type": "application/json"
				//"X-Correlation-Id": (window as any).correlationId
			}
		});
	}
	const onSuccess = (response: any) => {
		console.debug("Request Successful!", response);
		return response.data;
	};

	const onError = (error: any) => {
		console.error("Request Failed:", error.config);

		if (error.response) {
			// Request was made but server responded with something
			// other than 2xx
			console.error("Status:", error.response.status);
			//console.error("Data:", error.response.data);
			console.error("Headers:", error.response.headers);
		} else {
			// Something else happened while setting up the request
			// triggered the error
			console.error("Error Message:", error.message);
		}

		return Promise.reject(error.response || error.message);
	};

	if (localStorage.getItem("Identity.Token")) {
		//Add a header if we have one
		options.headers = { Authorization: `Bearer ${localStorage.getItem("Identity.Token") || ""}` };
	}

	return client(options)
		.then(onSuccess)
		.catch(onError);
};

export default request;
