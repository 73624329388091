import * as MobX from "mobx";
import { TemplateErrorSheet } from "./TemplateError";
import NavItem from "reactstrap/lib/NavItem";

export class Template {
	public Name: string = "";
	public Id: number = -1;
	@MobX.observable public WorkbookId: number = -1;
	@MobX.observable public IsUploading: boolean = false;
	@MobX.observable public IsRequired: boolean = false;
	@MobX.observable public IsValidated: boolean = false;
	@MobX.observable public IsVisible: boolean = false;
	@MobX.observable public Uploaded: boolean = false;
	@MobX.observable public HasError: boolean = false;
	public TemplateDependencies: Template[] = [];
	@MobX.observable public TemplateErrorSheet: TemplateErrorSheet[] = [];

	constructor(id: number, name: string, isRequired?: boolean, workbookId?: number ) {
		this.Id = id;
		this.Name = name;
		this.IsRequired = isRequired ? isRequired : false;
		this.IsVisible = this.IsRequired;
		this.WorkbookId = workbookId == null ? -1 : workbookId;
		// (this.TemplateErrorSheet as any).intercept((change: any): any => {
		//    console.log("Intercept");
		//    return null;
		// })
	}

	@MobX.computed
	get ErrorCount(): number {
		let count:number = 0;
		for (let i: number = 0; i < this.TemplateErrorSheet.length; i++) {
			let sheet: TemplateErrorSheet = this.TemplateErrorSheet[i];
			count += sheet.Sheets.length;
		}

		return count;
	}

	@MobX.action
	public SetHasError = (state: boolean) => {
		this.HasError = state;
	}

	@MobX.action
	public Reset = () => {
		this.HasError = false;
		this.Uploaded = false;
		this.IsUploading = false;
		this.TemplateErrorSheet = [];
	}
}
