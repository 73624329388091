import * as React from "react";
import * as ReactStrap from "reactstrap";
import UserHeader from "../Components/UserHeader";
import Sidebar from "../Components/Sidebar";
import "./DefaultLayout.scss";

export class LoggedOutLayout extends React.Component {
	public render() {
		return (
			<div className="defaultlayout w-100 h-100">
				<ReactStrap.Container fluid className="d-flex flex-column h-100 px-0">
					<div className="layoutwrapper h-100">
						<div className="d-flex w-100" id="content">
							{this.props.children}
						</div>
					</div>
				</ReactStrap.Container>
			</div>
		);
	}
}
