import * as jwt from "jsonwebtoken";

export const GetNewGuid = () => {
	return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);
};

export const ValidateJwtToken = (token: string) => {
	var decoded = jwt.decode(token);
	return decoded;
};

export const base64ToArrayBuffer = (base64: any) => {
	var binaryString = window.atob(base64);
	var binaryLen = binaryString.length;
	var bytes = new Uint8Array(binaryLen);
	for (var i = 0; i < binaryLen; i++) {
		var ascii = binaryString.charCodeAt(i);
		bytes[i] = ascii;
	}
	return bytes;
};

export const saveByteArray = (reportName: string, byte: any) => {
	var blob = new Blob([byte], { type: "application/vnd.ms-excel" });
	var link = document.createElement("a");
	link.href = window.URL.createObjectURL(blob);
	var fileName = reportName;
	link.download = fileName;
	link.click();
};
