import * as React from "react";
import * as Reactstrap from "reactstrap";
import * as MobX from "mobx";
import * as MobXReact from "mobx-react";

import axios from "axios";
import StoreContext from "../Stores/StoreContext";
import styles from "./Login.module.scss";
import UserStore from "../Stores/UserStore";
import { RouteComponentProps } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ButtonLoader } from "../Components/ButtonLoader";
import SessionService from "../Apis/AuthService";
import { AppUrls } from "../Utils/Shared";
import { Link } from "react-router-dom";
import BackgroundImg from "../Content/Images/Background.png";
import ISamsLogoImg from "../Content/Images/iSamsLogo.png";
import "./Login.scss";

interface Props extends RouteComponentProps {
	stores?: StoreContext;
}

interface State {
	Spinner: boolean;
}

const defaultState: State = {
	Spinner: false
};

@MobXReact.inject((context: { stores: StoreContext }) => ({ stores: context.stores }))
@MobXReact.observer
export class Login extends React.Component<Props, State> {
	private userStore: UserStore = this.props.stores!.UserStore as UserStore;
	state: State = defaultState;

	private login = () => {
		window.location.href = AppUrls.Server.loginWithSf;
	};
    render() {
        if (this.userStore.IsAuthenticated)
            this.props.stores.history.goForward();
		return (
			<div
				className={`h-100 w-100 container-fluid ${styles.wrapper}`}
				style={{
					backgroundImage: `url(${BackgroundImg})`,
					backgroundColor: `#252834`,
					backgroundSize: `cover`
				}}>
				<Reactstrap.Row className={`{styles.row} align-items-center justify-content-center h-100`}>
					<Reactstrap.Col lg={4} className="text-center py-5 px-5" style={{ backgroundColor: "#fff" }}>
						<div className="">
							<div className="text-left">
								<img src={ISamsLogoImg} alt="iSams logo" onClick={() => console.log("Test click.")}/>
								<p
									style={{
										marginTop: `4px`,
										color: `#9e9e9e`,
										fontWeight: 600,
										fontSize: `15px`
									}}>
									Document validator
								</p>
                            </div>

							<Reactstrap.Button
								color="primary"
								style={{ border: `none`, borderRadius: `4px`, width: `100%` }}
								className="p-4 my-3"
								onClick={this.login}>
								Login with iSAMS Identity
							</Reactstrap.Button>

							<br />
                            <br />

							<div>
								<Link to="/admin/login">Login as Admin</Link>
							</div>
						</div>
					</Reactstrap.Col>
				</Reactstrap.Row>
			</div>
		);
	}
}
