import * as React from "react";
import * as Reactstrap from "reactstrap";
import * as MobX from "mobx";
import * as MobXReact from "mobx-react";

import axios from "axios";
import { Upload } from "../Components/Upload/Upload";
import TemplateView from "../Components/TemplateView";
import YesNoModal from "../Components/YesNoModal";
import StoreContext from "../Stores/StoreContext";
import TemplateStore from "../Stores/TemplateStore";
import styles from "./Landing.module.scss";
import UserStore from "../Stores/UserStore";
import * as Utility from "../Utils";
import { RouteComponentProps } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faUpload, faBan, faLock, faUnlock, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { ButtonLoader } from "../Components/ButtonLoader";
import SessionService from "../Apis/SessionService";
import { TemplateError, TemplateErrorSheet, TemplateErrorType } from "../Models/TemplateError";
import "./Landing.scss";
import { ViewportWidthProperty } from "csstype";
import Button from 'reactstrap/lib/Button';

// tslint:disable-next-line: interface-name
interface Props extends RouteComponentProps<MatchParams> {
	stores?: StoreContext;
}

// tslint:disable-next-line: interface-name
interface State {
	ShowLoading: boolean;
	LoadingMessage: string;
	Uploading: boolean;
	timeElapsed: number;
	ShowYesNoModal: boolean;
	redFlash: boolean;
}

// tslint:disable-next-line: interface-name
export interface MatchParams {
	sessionId?: string;
}

const defaultState: State = {
	ShowLoading: false,
	LoadingMessage: "Finding current session...",
	Uploading: false,
	timeElapsed: 0,
	ShowYesNoModal: false,
	redFlash: false,
};

@MobXReact.inject((context: { stores: StoreContext }) => ({
	stores: context.stores
}))
@MobXReact.observer
export class Landing extends React.Component<Props, State> {
	private templateStore: TemplateStore = this.props.stores!.TemplateStore as TemplateStore;
	private userStore: UserStore = this.props.stores!.UserStore as UserStore;

	state: State = defaultState;

	@MobX.observable unknownFiles: string[] = []
	
	@MobX.action PushUnknownFiles(fileName: string) {
		this.unknownFiles.push(fileName)
	}

	@MobX.action ResetUnknownFiles() {
		this.unknownFiles = [];
	}
	
	componentDidMount(): void {
		this.setState({ ShowLoading: true });
		setInterval(this.incrementTimeElapsed.bind(this), 1000);

		if (this.props.match.params!.sessionId && this.props.match.params!.sessionId !== "") {
			this.userStore.SetSessionId(this.props.match.params!.sessionId);
			this.getCurrentState();
		} else {
			SessionService.getCurrent()
				.then((response: any) => {
					if (response !== "") {
                        this.userStore.SetSessionId(response);
						this.props.stores.history.push(`/landing/${response}`);
						this.getCurrentState();
					} else this.setState({ ShowLoading: false });
				})
				.catch((response: any) => {
					let a = 0;
				});
		}
	}

	private getCurrentState() {
		SessionService.get(this.userStore.SessionId)
			.then((response:any) => {
				console.log("Repsonse:");
				console.log(response);
				this.setState({ ShowLoading: false });
				this.templateStore.SetSubmitted(response.submitted);
				this.templateStore.SetActive(response.active);
				if (response.validated) {
					this.templateStore.SetValidated(true);
					this.templateStore.SetValidating(true);
				} else {
					console.log("Not validated.");
					this.templateStore.SetValidated(false);
					this.templateStore.SetValidating(false);
				}
				Object.keys(response).forEach(item => {
					this.templateStore.SetTemplateToUploaded(this.capitalizeFirstLetter(item), response[item]);
				});
			})
			.catch((response:any) => {
				if (response.status === 401) {
					this.templateStore.SetLoading(false);
					this.setState({ LoadingMessage: "Unauthorised" });
				}
			});
	}

	private onDropAccept = async (accepted: any[], rejected: any[]) => {
		this.setState({ Uploading: true });
		this.templateStore.SetValidating(false);
		this.ResetUnknownFiles();

		const pArray = accepted.map(file => {
			let fileName = "";

			if (file.name.indexOf(".xls") < 0 && file.name.indexOf(".xlsx") < 0) {
				this.PushUnknownFiles(file.name);
				return Promise.resolve();
			}

			//? Does the file exist in our predefined templates?
			fileName = this.getCleanedFilename(file.name);

			fileName = Utility.Typography.toPascalCase(fileName);

			if (!this.templateStore.TemplateContainer[fileName]) {
				this.PushUnknownFiles(fileName);
				return Promise.resolve();
			}

			this.templateStore.SetTemplateIsUploading(fileName);

			return SessionService.uploadFile(file, this.userStore.SessionId).then((response:any) => {
				this.templateStore.SetTemplateToUploaded(fileName);
			});
		});

		// Wait for all the posts to finish
		await Promise.all(pArray);
		this.setState({ Uploading: false });

		if (this.unknownFiles.length > 0) {
			this.setState({
					redFlash: true,
				})

			setTimeout(() => { //Start the timer
				this.setState({ redFlash: false }) //After 1 second, set render to true
			}, 1000)
		}
	}

	private capitalizeFirstLetter = (string: string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	private onCancel = () => { };

	private onDelete = () => { };

	private getCleanedFilename(fileName: string): string {
		if (fileName.indexOf("_") > 0) {
			fileName = fileName.split("_")[1];
		}

		fileName = fileName.split(".")[0];

		if (fileName.indexOf("- Independent") > 0) {
			this.props.stores!.TemplateStore.SetSchoolDependantStatus(true);
		}

		if (fileName.indexOf("-") > 0) {
			fileName = fileName.split("-")[0].trim();
		}

		return fileName;
	}

	private StartNewSession = () => {
		this.templateStore.SetLoading(false);
		SessionService.create()
			.then((data:any) => {
				let sessionId: string = data;
				this.userStore.SetSessionId(sessionId);
				this.props.stores!.history.push("/landing/" + sessionId);
				this.templateStore.SetLoading(false);
			})
			.catch(() => {
				this.templateStore.SetLoading(false);
			});
	}

	private AbortSession = () => {
		this.templateStore.SetLoading(true);
		SessionService.abort(this.userStore.SessionId)
			.then(() => {
				this.templateStore.SetLoading(false);
				this.setState({ ShowLoading: false });
				this.userStore.AbortSession();
				this.templateStore.Reset();
				this.setState(defaultState);
				this.ResetUnknownFiles();
				this.props.stores!.history.push("/landing/");
			})
			.catch(() => {
				this.templateStore.SetLoading(false);
			});
	}

	private Validate = () => {
		this.setState({
			timeElapsed: 0,
		});
		if (this.templateStore.CanValidate) {
			var errorsFound: boolean = false;
			this.templateStore.SetErrored(errorsFound);
			this.templateStore.SetLoading(true);
			SessionService.validate(this.userStore.SessionId).then((response: any[]) => {
				for (var templateName in response) {
					var pascalCasedName = Utility.Typography.toPascalCase(templateName);
					var cleanedName = this.getCleanedFilename(pascalCasedName).replace(" ", "");
					var template = this.templateStore.TemplateContainer[cleanedName];

					MobX.runInAction(() => {
						template.TemplateErrorSheet = [];
					});

					for (var sheetName in response[templateName]) {
						var sheetErrors = response[templateName][sheetName];
						var sheet: TemplateErrorSheet = new TemplateErrorSheet();
						sheet.Name = sheetName;

						for (let i: number = 0; i < sheetErrors.length; i++) {
							var error: TemplateError = new TemplateError();
							error.Description = sheetErrors[i].description;
							error.RowNumber = sheetErrors[i].rowNumber;
							error.ColumnName = sheetErrors[i].columnName;
							error.ErrorType = sheetErrors[i].errorType;
							sheet.Sheets.push(error);
							errorsFound = true;
						}
						MobX.runInAction(() => {
							template.TemplateErrorSheet.push(sheet);
						});
					}
				}
				this.templateStore.SetLoading(false);
				this.templateStore.SetValidating(true);
				if (errorsFound) {
					this.templateStore.SetErrored(errorsFound);
				} else {
					this.templateStore.SetValidated(true);
					this.templateStore.SetSubmitted(true);
				}
			})
				.catch(() => {
					console.log("Catch");
					this.templateStore.Reset(true);
					window.alert("An unknown error occured while validating your files.");
				});
		}
	}

	private Submit = () => {
		console.log("Submitted.");
		this.templateStore.SetLoading(true);

		SessionService.submit(this.userStore.SessionId).then((response: any[]) => {
			this.templateStore.SetValidated(true);
			this.templateStore.SetSubmitted(true);
		}).catch(() => {
			this.templateStore.Reset(true);
			window.alert("An unknown error occured while submitting your files.");
		});
	}

	private incrementTimeElapsed() {
		this.setState({
			timeElapsed: this.state.timeElapsed + 1,
		});
	}

	render() {
		if (this.state.ShowLoading) {
			return <div className="py-4 px-4 w-100">
				<h4><FontAwesomeIcon icon={faSpinner} spin /> {this.state.LoadingMessage}</h4>
			</div>;
		}
		else {
			return (
				<div className={`w-100 ${styles.wrapper} landing`}>
					{this.templateStore.Errored && (
						<Reactstrap.Alert className="mb-0" color="danger">
							Your documents currently have errors. Please fix the issues found and re-submit to iSams
						</Reactstrap.Alert>
					)}
					{this.templateStore.Validated && this.templateStore.Submitted && (
						<Reactstrap.Alert className="mb-0" color="success">
							<FontAwesomeIcon icon={faLock} className="mr-2" />
							You have successfully submitted your documents to iSams. You will be notified once they are processed.
						</Reactstrap.Alert>
					)}
					{this.templateStore.Validated === false && this.templateStore.Submitted && (
						<Reactstrap.Alert className="mb-0" color="info">
							<FontAwesomeIcon icon={faUnlock} className="mr-2" />
							Your documents have been reviewed. Session unlocked for any additional uploads / adjustments.
						</Reactstrap.Alert>
					)}

					{this.templateStore.Validated && this.templateStore.Submitted ?
						<div></div>
						:
						<Reactstrap.Row className="py-4" style={{
							backgroundColor: `#f9f8f7`,
							padding: `10px`,
							borderBottom: `1px solid #E0E1E1`
						}}>
							<Reactstrap.Col>
								<div className="text-center">
									{this.userStore.SessionId === "" ? (
										<ButtonLoader loading={this.templateStore.isLoading} onClick={this.StartNewSession}>
											Start New Session
									</ButtonLoader>
									) : !this.templateStore.Validated ? (
										<React.Fragment>
											<ButtonLoader
												loading={this.templateStore.isLoading}
												color={this.templateStore.CanValidate ? "primary" : "secondary"}
												className={this.templateStore.CanValidate ? "mr-2" : "mr-2 disabled"}
												onClick={this.Validate}>
												<FontAwesomeIcon icon={faUpload} className="mr-2" />
												Validate & submit
											</ButtonLoader>
											<ButtonLoader
												loading={this.templateStore.isLoading}
												color={this.templateStore.CanValidate ? "primary" : "secondary"}
												className={this.templateStore.CanValidate ? "mr-2" : "mr-2 disabled"}
												onClick={this.Submit}>
												<FontAwesomeIcon icon={faUpload} className="mr-2" />
												Submit (No validation)
											</ButtonLoader>
											<ButtonLoader
												color="danger"
												loading={this.templateStore.isLoading}
												onClick={() => { this.setState({ ShowYesNoModal: true }) }}>
												<FontAwesomeIcon icon={faBan} className="mr-2" />
												Cancel Submission
										</ButtonLoader>
										</React.Fragment>
									) : (
												<React.Fragment />
											)}
								</div>
							</Reactstrap.Col>
						{this.templateStore.isLoading &&
							<Reactstrap.Col sm={12}>
								<div className="text-center">
									<p className="mb-0 mt-2" style={{
										color: `#949494`,
										fontSize: `11px`
									}}>
										Time elapsed: {this.state.timeElapsed}
									</p>
								</div>
							</Reactstrap.Col>
						}
					</Reactstrap.Row>
					}

					<div className={this.userStore.SessionId === "" ? "d-none" : ""}>
						{!this.templateStore.Validated ? (
							<React.Fragment>
								<Reactstrap.Row className={`py-2`} style={{
										backgroundColor: `#f9f8f7`,
										padding: `10px`,
										borderBottom: `1px solid #E0E1E1`
									}}
								>
									<Reactstrap.Col className="pl-0" style={{ fontSize: `13px` }}>
										Your uploaded workbooks (
										{this.props.stores!.TemplateStore.SchoolIsIndependent
											? "Independent School"
											: "Non-Independent School"}
										)
									</Reactstrap.Col>
								</Reactstrap.Row>
								<Upload
									className={`landing-upload`}
									onCancel={this.onCancel}
									onDropAccept={this.onDropAccept}
									onDelete={this.onDelete}
								/>
							</React.Fragment>
						) : (
								<React.Fragment />
							)}

						<div style={{ borderTop: `1px solid #E0E1E1` }}>
							<Reactstrap.Row>
								<Reactstrap.Col className="py-2 pl-2">
									<strong className="f-13">Required workbooks</strong>
								</Reactstrap.Col>
							</Reactstrap.Row>

							<Reactstrap.Row>
								<Reactstrap.Col>
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.Buildings} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.OtherSchools} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.StaffData} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.SchoolStructure} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.AcademicStructure} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.PupilData} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.TimetableStructure} />
								</Reactstrap.Col>
							</Reactstrap.Row>

							<Reactstrap.Row>
								<Reactstrap.Col>
									<hr style={{ borderTop: `4px dotted #5D5B69` }} />
								</Reactstrap.Col>
							</Reactstrap.Row>

							<Reactstrap.Row>
								<Reactstrap.Col className="py-2 pl-2">
									<strong className="f-13">Additional & linked workbooks</strong>
									{(this.templateStore.OtherFilesUploaded == 0 && this.state.Uploading  == false) &&
										<div className="ml-4 mt-2">
											No additional or linked workbooks have been uploaded.
										</div>
									}
									{(this.templateStore.OtherFilesUploaded == 0 && this.state.Uploading) &&
										<div className="ml-4 mt-2">
											Scanning your uploaded documents...
										</div>
									}
								</Reactstrap.Col>
							</Reactstrap.Row>

							<Reactstrap.Row>
								<Reactstrap.Col>
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.Doctors} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.Users} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.MedicalCentre} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.Admissions} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.Agencies} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.Documents} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.FeeBilling} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.Discipline} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.ExternalExams} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.InternalExams} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.PastoralManager} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.Registrations} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.ReportsAndAssessments} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.RewardsAndConducts} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.Activities} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.NewSchool} />
									<TemplateView className={styles.row} template={this.templateStore.TemplateContainer.ExistingSchool} />
								</Reactstrap.Col>
							</Reactstrap.Row>

							<Reactstrap.Row>
								<Reactstrap.Col>
									<hr style={{ borderTop: `4px dotted #5D5B69` }} />
								</Reactstrap.Col>
							</Reactstrap.Row>

							<Reactstrap.Row>
								<Reactstrap.Col className="py-2 pl-2 mb-5">
									<div className={`${this.state.redFlash && 'red-flash'}`}>
										<strong className="f-13">Unknown & rejected files {this.unknownFiles.length > 0 && <FontAwesomeIcon style={{ color: `#efa212`, fontSize: `16px` }} icon={faExclamationTriangle} />}</strong>
									</div>
										<Reactstrap.Collapse isOpen={true}>
										<div className="ml-4">
											{this.unknownFiles.length > 0 &&
												<ul className="mt-3">
													{this.unknownFiles.map((file, index) => {
														return <li key={"reject-" + index}>{file}</li>;
													})}
												</ul>
											}
										</div>
									</Reactstrap.Collapse>
									{this.unknownFiles.length == 0 &&
										<div className="ml-4 mt-2">There are currently no unknown / rejected files with your uploads.</div>
									}
								</Reactstrap.Col>
							</Reactstrap.Row>
						</div>
					</div>

					<YesNoModal
						IsOpen={this.state.ShowYesNoModal}
						Title="Confirmation"
						Body={
							<div>
								<div>Are you sure you want to abort this session?</div>
								<div>This action cannot be undone.</div>
							</div>
						}
						OnYes={this.AbortSession}
						OnNo={() => this.setState({ ShowYesNoModal: false })}
					/>
				</div>
			);
		}
	}
}