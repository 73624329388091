import { Template } from "./Template";

export class TemplateContainer {
	public SessionId: string;
	//public IsSchoolIndependent: boolean;
	public Buildings: Template;
	public OtherSchools: Template;
	public StaffData: Template;
	public SchoolStructure: Template;
	public AcademicStructure: Template;
	public PupilData: Template;
	public Doctors: Template;
	public Users: Template;
	public TimetableStructure: Template;
	public MedicalCentre: Template;
	public Admissions: Template;
	public Agencies: Template;
	public Documents: Template;
	public FeeBilling: Template;
	public Discipline: Template;
	public ExternalExams: Template;
	public InternalExams: Template;
	public PastoralManager: Template;
	public Registrations: Template;
	public ReportsAndAssessments: Template;
	public RewardsAndConducts: Template;
	public Activities: Template;
	public NewSchool: Template;
	public ExistingSchool: Template;
	public SEN?: Template;
	[key: string]: Template | any;
}
