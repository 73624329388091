import * as React from "react";
import * as Reactstrap from "reactstrap";
import * as MobX from "mobx";
import * as MobXReact from "mobx-react";
import StoreContext from "../../Stores/StoreContext";
import TemplateStore from "../../Stores/TemplateStore";
import UserStore from "../../Stores/UserStore";
import AdminStore from "../../Stores/AdminStore";
import SessionService from "../../Apis/SessionService";
import AdminService from "../../Apis/AdminService";
import { AdminRow } from "./AdminRow";
import { RouteComponentProps } from "react-router";
import "./AdminView.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Misc } from "../../Utils";
import { clearScreenDown } from "readline";

interface Props extends RouteComponentProps<MatchParams> {
	stores?: StoreContext;
}

interface State {
	isLoading: boolean;
}

export interface MatchParams {
	sessionId?: string;
}

@MobXReact.inject((context: { stores: StoreContext }) => ({ stores: context.stores }))
@MobXReact.observer
export class AdminView extends React.Component<Props, State> {
	private adminStore: AdminStore = this.props.stores!.AdminStore as AdminStore;
	state: State = {
		isLoading: true
	};

	componentDidMount = () => {
		this.setState({
			isLoading: true
		});

		AdminService.getUserSubmissions().then((submissions:any) => {
			this.adminStore.SetUserSubmissions(submissions);
			this.setState({
				isLoading: false
			});
		});
	};

	render() {
		return (
			<div className={`{styles.row} h-100 admin-view`}>
				<Reactstrap.Row className="grey-head-row">
					<Reactstrap.Col>Submitted workbooks</Reactstrap.Col>
				</Reactstrap.Row>

				<Reactstrap.Row className="grey-head-row fs-13">
					<Reactstrap.Col lg={6}>Submitted by</Reactstrap.Col>

					<Reactstrap.Col lg={2}>No. of workbooks</Reactstrap.Col>

					<Reactstrap.Col lg={2}>Date submitted</Reactstrap.Col>

					<Reactstrap.Col lg={2}>Status</Reactstrap.Col>
				</Reactstrap.Row>

				<div>
					{this.state.isLoading && (
						<Reactstrap.Row className="my-3">
							<Reactstrap.Col>
								<FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> Fetching submitted files...
							</Reactstrap.Col>
						</Reactstrap.Row>
					)}
					{this.adminStore.UserSubmissions.map((submisison: any, index: number) => {
                        return <AdminRow index={index} key={'adrw-' + index}/>;
					})}
					{this.adminStore.NoSubmissions && (
						<Reactstrap.Row className="my-3">
							<Reactstrap.Col>There is currently no submitted data</Reactstrap.Col>
						</Reactstrap.Row>
					)}
				</div>
			</div>
		);
	}
}
