import * as React from "react";
import * as Reactstrap from "reactstrap";
import * as MobXReact from "mobx-react";
import AdminService from "../../Apis/AdminService";
import AdminStore from "../../Stores/AdminStore";
import StoreContext from "../../Stores/StoreContext";
import { saveAs } from "file-saver";
import styles from "../../Styles/Indicators.module.scss";
import "./AdminWorkbook.scss";
import { AppUrls } from "../../Utils/Shared";
import { Misc } from "../../Utils";

interface Props {
	stores?: StoreContext;
	workbookNumber?: number;
	index: number;
	workbookName: string;
	exists: boolean;
}

@MobXReact.inject((context: { stores: StoreContext }) => ({ stores: context.stores }))
@MobXReact.observer
export class AdminWorkbook extends React.Component<Props> {
	private adminStore: AdminStore = this.props.stores!.AdminStore as AdminStore;

	render() {
		const { workbookName, workbookNumber, exists } = this.props;

		if (exists === false) {
			return null;
		}

		return (
			<Reactstrap.Row className={`admin-workbook ${workbookNumber === 6 && "no-botbor"}`}>
				<Reactstrap.Col lg={2}>
					<div className="d-flex align-items-center h-100">
						<div>
							<div className={`indicator ${exists ? styles.numberBadge : styles.emptyBadge}`}>
								<span>{workbookNumber}</span>
							</div>
						</div>
						<div className="ml-3 name">{workbookName}</div>
					</div>
				</Reactstrap.Col>
				<Reactstrap.Col lg={1} className="px-0">
					<div className="d-flex align-items-center h-100">
						<div className="ml-5 download" onClick={() => this.downloadWorkbook()}>
							Download file
						</div>
					</div>
				</Reactstrap.Col>
			</Reactstrap.Row>
		);
	}

	private downloadWorkbook = () => {
		AdminService.getDownloadStream(
			this.adminStore.UserSubmissions[this.props.index].sessions.sessionId,
			this.props.workbookName
		).then((workbook:any) => {
			let sampleArr = Misc.base64ToArrayBuffer(workbook);
			Misc.saveByteArray(this.props.workbookName, sampleArr);
		})
		.catch(() => {
			window.alert("Failed to download file - Could not be found.");
		});
	};
}
