import "core-js";
import "./Site.scss";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import { Landing } from "./Pages/Landing";
import * as History from "history";
import * as MobX from "mobx";
import * as MobXReact from "mobx-react";
import StoreContext from "./Stores/StoreContext";
import { DefaultLayout } from "./Layouts/DefaultLayout";
import * as shared from "./Utils/Shared";
import { Login } from "./Pages/Login";
import { AdminLogin } from "./Pages/Admin/AdminLogin";
import { PrivateADRoute } from "./Components/PrivateADRoute";
import { PrivateSFRoute } from "./Components/PrivateSFRoute";
import { AdminView } from "./Pages/Admin/AdminView";
import {
	PrivateRoute,
	CombineAccessTests,
	RequiresLoggedIn,
	CreateRequiresPermission
} from "./Components/PrivateRoute";
import { Layout } from "./Layouts/Layout";
//import * as Msal from "msal";

// https://github.com/mobxjs/mobx/blob/gh-pages/docs/refguide/api.md#configure
// https://github.com/mobxjs/mobx/issues/1710
MobX.configure({ enforceActions: "observed" });

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href")!;
const rootElement = document.getElementById("app-root");
const loggedInAndAdminPermission = CombineAccessTests(RequiresLoggedIn, CreateRequiresPermission("admin"));
const loggedInAndUploadPermission = CombineAccessTests(RequiresLoggedIn, CreateRequiresPermission("upload"));

const render = () => {
	console.log("Render main component");
	const history: History.History<any> = History.createBrowserHistory({
		basename: baseUrl
	});

	const storeContext: StoreContext = new StoreContext(history);

	(window as any).storeContext = storeContext;

	function login() {
		window.location.href = "/api/session/test";
	}

	ReactDOM.render(
		<MobXReact.Provider stores={storeContext}>
			<Router history={history}>
				<Layout>
					<Switch>
						<Route path="/login" component={Login} />
                        <Route exact path="/admin/login" component={AdminLogin} />

                        <PrivateRoute
                            accessTest={loggedInAndAdminPermission}
                            exact path="/admin"
                            component={AdminView}
                        />

						<PrivateRoute
							accessTest={loggedInAndUploadPermission}
							exact path="/session/:sessionId?"
							component={Landing}
                        />

						<PrivateRoute
							accessTest={loggedInAndUploadPermission}
							exact path="/landing/:sessionId?"
							component={Landing}
                        />

                        <PrivateRoute
                            accessTest={loggedInAndUploadPermission}
                            path="/"
                            component={Landing}
                        />
					</Switch>
				</Layout>
			</Router>
		</MobXReact.Provider>,
		rootElement
	);
};

shared.getBaseUrl().then((response:any) => {
	shared.AppUrls.Server.apiBaseUrl = response.data;
	(window as any).apiBaseUrl = response.data;
	render();
});
