import React from 'react'
import * as MobX from "mobx";
import * as MobXReact from "mobx-react";
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import * as Reactstrap from "reactstrap";
import StoreContext from '../../Stores/StoreContext';
import TemplateStore from '../../Stores/TemplateStore';
import UserStore from '../../Stores/UserStore';
import styles from "../../Pages/Landing.module.scss";
import UploadImg from "../../Content/Images/Upload.png";
import "./Upload.scss";

interface Props {
    onDropAccept: any;
    onDelete: any;
    onCancel: any;
    className: string;
    stores?: StoreContext;
}

interface State {
    uploading: boolean;
    collapse: boolean;
    dragAccept: boolean;
    dragReject: boolean;
}

@MobXReact.inject((context: { stores: StoreContext }) => ({ stores: context.stores }))
@MobXReact.observer
export class Upload extends React.Component<Props, State> {
    private templateStore: TemplateStore = this.props.stores!.TemplateStore as TemplateStore;
    private userStore: UserStore = this.props.stores!.UserStore as UserStore;
    private dropzoneRef = React.createRef();

    state: State = {
        uploading: false,
        collapse: true,
        dragAccept: false,
        dragReject: false
    }

    private getFileCount = () => {
        var count = this.templateStore.TotalFilesUploaded;
        var singular = "files";
        if (count == 1) {
            singular = "file";
        }

        return `${count} ${singular} uploaded`
    }

    render() {
        const baseStyle = {
            width: '100%',
            height: 100,
            borderWidth: 2,
            borderColor: 'rgb(167, 167, 167)',
            borderStyle: 'dashed',
            borderRadius: 0,
            backgroundColor: 'white'
        };
        const activeStyle = {
            borderStyle: 'solid',
            borderColor: '#6c6',
            backgroundColor: '#eee'
        };
        const rejectStyle = {
            borderStyle: 'solid',
            borderColor: '#c66',
            backgroundColor: '#eee'
        };
        const headerRowStyle = {
            borderBottom: `1px solid #e0e1e1`,
            backgroundColor: `#f9f8f7`
        }

        return (<div style={{backgroundColor: `#f9f8f7`}} className="pb-3 upload">
            <Reactstrap.Row style={headerRowStyle} className="mb-3">
                <Reactstrap.Col className="py-2" style={{fontSize: `13px`, paddingLeft: `10px`}}>
                    {this.getFileCount()}
                </Reactstrap.Col>              
            </Reactstrap.Row>
            <Reactstrap.Row className={this.props.className}>
                <Reactstrap.Col>
                    <div>
                        <Reactstrap.Collapse isOpen={this.state.collapse} >
                            <Dropzone
                                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onDrop={this.props.onDropAccept}
                                onFileDialogCancel={this.props.onCancel}
                            >
                            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                                let styles = { ...baseStyle };
                                return (
                                    //let styles = {...baseStyle};
                                    <div {...getRootProps()} className={`uploader uploader-${isDragAccept ? 'accept' : isDragReject ? 'reject' : ''}`}>
                                        <input {...getInputProps()} />
                                        
                                        <div className="text-center"
                                            style={{
                                                lineHeight: `96px`,
                                                color: `#797681`,
                                                fontSize: `14px`,
                                                backgroundImage: `url(${UploadImg})`,
                                                backgroundPosition: `center`,
                                                backgroundRepeat: `no-repeat`
                                            }}
                                        >
                                            Click or drag a file here to upload your workbook
                                        </div>
                                    </div>
                                )
                            }}
                            </Dropzone>
                        </Reactstrap.Collapse>
                    </div>
                </Reactstrap.Col>
            </Reactstrap.Row>
        </div>);
    }
}