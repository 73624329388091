import * as shared from "../Utils/Shared";
import request from "../Utils/Axios";
import { TemplateContainer } from "../Models/TemplateContainer";

export const get = (sessionId: string) => {
	const fetchUrl = `${shared.AppUrls.Server.findSessionUrl}/${sessionId}`;
	return request({
		url: fetchUrl,
		method: "GET"
	});
};

export const getCurrent = () => {
	const fetchUrl = `${shared.AppUrls.Server.findCurrentSessionUrl}`;
	console.log("getcurrent");
	console.log(fetchUrl);
	return request({
		url: fetchUrl,
		method: "GET"
	});
};

export const getOpenSessions = () => {
	const fetchUrl = `${shared.AppUrls.Server.getOpenSessions}`;
	console.log("getOpenSessions");
	console.log(fetchUrl);
	return request({
		url: fetchUrl,
		method: "GET"
	});
};

export const uploadFile = (file: any, sessionId: string) => {
	const token = localStorage.getItem("Identity.Token");
	const fetchUrl = shared.AppUrls.Server.uploadFileUrl;
	var data = new FormData();
	data.append("file", file);
	data.append("guid", sessionId);

	return request({
		url: fetchUrl,
		method: "POST",
		data: data,
		headers: {
			"Content-Type": "multipart/form-data"
			//'Authorization': `Bearer ${token}`
		}
	});
};

export const create = () => {
	const fetchUrl = shared.AppUrls.Server.createSessionUrl;
	return request({
		url: fetchUrl,
		method: "POST"
	});
};

export const abort = (sessionId: string) => {
	const fetchUrl = shared.AppUrls.Server.abortSessionUrl;
	return request({
		url: fetchUrl,
		method: "POST",
		data: { sessionId: sessionId }
	});
};

export const validate = (sessionId: string) => {
	const fetchUrl = shared.AppUrls.Server.validateAllFiles;
	return request({
		url: fetchUrl,
		method: "POST",
		data: { sessionId: sessionId }
	})
};

export const submit = (sessionId: string) => {
	const fetchUrl = shared.AppUrls.Server.submitAllFiles;
	return request({
		url: fetchUrl,
		method: "POST",
		data: { sessionId: sessionId }
	})
};

const SessionService = {
	get,
	getCurrent,
	uploadFile,
	create,
	abort,
	validate,
	submit,
	getOpenSessions
};

export default SessionService;
