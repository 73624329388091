// Libs
import * as MobXReact from "mobx-react";
import * as React from "react";
import * as ReactRouterDom from "react-router-dom";

// App
import { AppUrls } from "../Utils/Shared";
import StoreContext from "../Stores/StoreContext";

export type AccessTest = (path: string | undefined, stores: StoreContext | undefined) => string | undefined;

export interface Props extends ReactRouterDom.RouteProps {
	accessTest: AccessTest;
	stores?: StoreContext;
}

export const RequiresLoggedIn: AccessTest = (path: string | undefined, stores: StoreContext | undefined) => {
	const failedPath = path ? AppUrls.Client.loginWithReturnPath.replace("/:returnPath?", path) : AppUrls.Client.login;

	if (stores === undefined) {
		return failedPath;
	}

	if (stores.UserStore.IsAuthenticated !== true) {
		return failedPath;
	}

	return undefined;
};

export const CreateRequiresPermission = (permissionTarget: string, permissionLevel?: "read" | "write"): AccessTest => (
	path: string | undefined,
	stores: StoreContext | undefined
) => {
	const failedPath = AppUrls.Client.Error.AccessDenied;

	if (stores === undefined) {
		return failedPath;
	}

	if (stores.UserStore.hasPermission(permissionTarget, permissionLevel) === false) {
		return failedPath;
	}

	return undefined;
};

export const CombineAccessTests = (...accessTests: AccessTest[]): AccessTest => (
	path: string | undefined,
	stores: StoreContext | undefined
) => {
	for (const accessTest of accessTests) {
		const accessTestResult = accessTest(path, stores);

		if (accessTestResult != null) {
			return accessTestResult;
		}
	}

	return undefined;
};

@MobXReact.inject((context: { stores: StoreContext }) => ({ stores: context.stores }))
@MobXReact.observer
export class PrivateRoute extends ReactRouterDom.Route<Props> {
	public displayName = PrivateRoute.name;

	public render() {
		const { accessTest, location, stores } = this.props;

		const redirectPath = accessTest(location ? location.pathname : "", stores);

		return redirectPath === undefined ? super.render() : <ReactRouterDom.Redirect to={redirectPath} />;
	}
}
