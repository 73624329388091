import styles from "./TemplateView.module.scss";

import * as React from "react";
import { Badge, Row, Col, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import { Template } from "../Models/Template";
import { TemplateError, TemplateErrorType } from "../Models/TemplateError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faChevronUp, faSchool } from "@fortawesome/free-solid-svg-icons";
import * as MobX from "mobx";
import * as MobXReact from "mobx-react";
import ErrorView from "../Components/ErrorView";
import StoreContext from "../Stores/StoreContext";
import TemplateStore from "../Stores/TemplateStore";
import UpArrow from "../Components/SVGs/UpArrow";
import './TemplateView.scss';
import { relative } from 'path';

// tslint:disable-next-line: interface-name
interface Props {
	stores?: StoreContext;
	template: Template;
	className?: string;
}

// tslint:disable-next-line: interface-name
interface State {
	collapse: boolean;
	badgeColor: string;
}

@MobXReact.inject((context: { stores: StoreContext }) => ({
	stores: context.stores
}))
@MobXReact.observer
export default class TemplateView extends React.Component<Props, State> {
	public templateStore: TemplateStore = this.props.stores!.TemplateStore as TemplateStore;

	state: State = {
		collapse: false,
		badgeColor: "secondary"
	};

	private getStyle(): string {
		if (this.props.template.ErrorCount > 0) {
			return `${styles.errored}`;
		}
		return "validated";
	}

	private getIndicatorColour(): string {
		if (this.props.template.ErrorCount > 0) {
			return `${styles.errorIndicator}`;
		} else if (this.props.template.ErrorCount === 0 && this.templateStore.Validating) {
			return `${styles.validIndicator}`;
		}
		return `${styles.noIndicator}`;
	}

	private getWorkbookNumber(): string {
		return this.props.template.WorkbookId === -1 ? "" : this.props.template.WorkbookId.toString();
	}

    render(): JSX.Element {
        let showChevrons: boolean = this.showChevrons();

		return (
			<div
				className={`${
					this.props.template.IsRequired || this.props.template.Uploaded || this.props.template.IsVisible
						? ""
						: "d-none"
				}`}>
				<div
					className={`${this.props.className} ${this.getStyle()} d-flex align-items-center`}
					onClick={() => showChevrons === true ? this.setState({ collapse: !this.state.collapse }) : this.setState({ collapse: false })}>
					{this.templateStore.Validated && this.templateStore.Submitted ?
						<div className="up-arrow">
							<UpArrow />
						</div>
						:
						<div style={{position: `relative`}} className={`${this.getIndicatorColour()} d-inline-block mr-4  + ${styles.indicator}`} >
							<span style={{position: `absolute`, top: `7px`, right: `5px`}}>{this.getWorkbookNumber()}</span>
						</div>
					}
					<div>{this.props.template.Name}</div>
					{!this.templateStore.Validated && !this.templateStore.Submitted &&
						<React.Fragment>
							<div className="ml-auto">
								<span className="mr-5">
									{this.getStatus()}
								</span>
							</div>
							{showChevrons === true &&
								<div className="mr-4">
									<FontAwesomeIcon
										className={`${styles.rotate} ${this.state.collapse ? styles.rotateDown : ""}`}
										icon={faChevronUp}
									/>
								</div>
							}
						</React.Fragment>
					}
				</div>

				{!this.templateStore.Validated && !this.templateStore.Submitted &&
					<Row>
						<Col>
							<Collapse className="error-collapse" isOpen={this.state.collapse}>
								{/* JJ: Collapse; moved the padding & margin to the child div, not the Collapse. Vertical spacing causes a horrible
									jerky, freezing animation. Always wrap collapse contents in a div and then apply styling to that. */}
								<div className={`py-3 px-3 mb-2 ${this.props.template.ErrorCount > 0 ? 'red-border' : 'neut-border'}`}>
									<ErrorView template={this.props.template} />
								</div>
							</Collapse>
						</Col>
					</Row>
				}
			</div>
		);
	}

	private getStatus(): JSX.Element {
		if (this.props.template.ErrorCount > 0) {
			return (
				<React.Fragment>
					<Badge color="danger" className={styles.badge}>
						<div>Failed</div>
					</Badge>
					<div className={"ml-1 " + styles.errorcounter}>
						{this.props.template.ErrorCount} error{this.props.template.ErrorCount === 1 ? "" : "(s)"}
					</div>
				</React.Fragment>
			);
		} else if (this.props.template.ErrorCount === 0 && this.templateStore.Validating) {
			return (
				<Badge color="success" className={styles.badge}>
					Validated
				</Badge>
			);
		} else if (this.props.template.Uploaded) {
			return (
				<Badge color="primary" className={styles.badge}>
					Uploaded
				</Badge>
			);
		} else if (this.props.template.IsUploading) {
			return (
				<Badge color="primary" className={styles.badge}>
					<FontAwesomeIcon icon={faSpinner} spin />
					<span className={`ml-1`}>Uploading</span>
				</Badge>
			);
		} else {
			return (
				<Badge  className={styles.badge + " " + styles.notuploaded}>
					Not Uploaded
				</Badge>
			);
		}
	}

    private showChevrons(): boolean {
        if (this.props.template.ErrorCount > 0) {
            return true;
		}
		else {
			return false;
		}
    }
}
