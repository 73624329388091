import * as React from 'react';

export const Avi = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
        <g id="Group_1813" data-name="Group 1813" transform="translate(-718.66 -149.27)">
            <path id="Path_4261" data-name="Path 4261" d="M754.66,167.255a18,18,0,1,0-29.179,14.081,11.01,11.01,0,0,1,8.7-9.348,7.372,7.372,0,0,1,2.481-14.341,6.45,6.45,0,0,1,1.707.213,7.341,7.341,0,0,1,5.746,7.168,6.8,6.8,0,0,1-.63,2.925c-.015.035-.087.19-.122.268a1.225,1.225,0,0,0-.085.144,7.309,7.309,0,0,1-4.142,3.611,11,11,0,0,1,8.38,7.958,13.587,13.587,0,0,1,.325,1.4A17.937,17.937,0,0,0,754.66,167.255Z" fill="#c8e4fc"/>
            <path id="Path_4262" data-name="Path 4262" d="M739.121,172.147a7.305,7.305,0,0,0,4.125-3.638,1.243,1.243,0,0,1,.085-.145c.035-.079.107-.235.122-.27a6.92,6.92,0,0,0,.627-2.947,7.378,7.378,0,0,0-5.722-7.222,6.354,6.354,0,0,0-1.7-.215,7.438,7.438,0,0,0-2.471,14.448,11.036,11.036,0,0,0-8.662,9.418,17.722,17.722,0,0,0,22.265,0,13.859,13.859,0,0,0-.324-1.413A11.009,11.009,0,0,0,739.121,172.147Z" transform="translate(0 -0.24)" fill="#58b1f5"/>
        </g>
    </svg>
}

export default Avi;